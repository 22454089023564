import EvaluationLayout from "./EvaluationLayout";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie-player";
import lottieJson from "static/lottie/thumbs-up.json";
import progress1Json from "static/lottie/progress-1.json";
import progress2Json from "static/lottie/progress-2.json";
import progress3Json from "static/lottie/progress-3.json";
import progressAcademic1Json from "static/lottie/progress-academic-1.json";
import progressAcademic2Json from "static/lottie/progress-academic-2.json";
import progressAcademic3Json from "static/lottie/progress-academic-3.json";
import progressAcademic4Json from "static/lottie/progress-academic-4.json";
import progressAcademic5Json from "static/lottie/progress-academic-5.json";

const LOTTIE_MAP = {
  "1B": progress1Json,
  2: progress2Json,
  3: progress3Json,
  "TAI": progressAcademic1Json,
  "GAD7": progressAcademic2Json,
  "PSS14": progressAcademic3Json,
  "ADHOC": progressAcademic4Json,
  "ASAI": progressAcademic5Json,
};

const StepCompleteAnimation = ({ onContinue, onPrevious, testKey }) => {
  const { t } = useTranslation();
  return (
    <EvaluationLayout onContinue={onContinue} onPrevious={onPrevious} hidePrimaryBtn>
      <div className="loading">
        <Text textStyle={TEXT_STYLES.largeLight}>{t("evaluation.loader_text")}</Text>
        <Lottie loop animationData={lottieJson} play className="animation" />
        {LOTTIE_MAP[testKey] && <Lottie loop animationData={LOTTIE_MAP[testKey]} play className="animation" />}
      </div>
    </EvaluationLayout>
  );
};

export default StepCompleteAnimation;
