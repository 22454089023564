import EvaluationLayout from "./EvaluationLayout";
import Mcq from "components/mcq/Mcq";
import "./TestStep.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { EVALUATION_TYPES } from "pages/Evaluation";

const AcademicTestStep = ({ data, testData, submitTestAnswers, evaluationType, submittedAnswers, isSubmittingAnswers, onPrevious }) => {
  const { key } = data;
  const { description, questions } = testData;
  const sessionStorageKey =
    evaluationType === EVALUATION_TYPES.INITIAL
      ? "initialTestAnswers"
      : "finalTestAnswers";

  const initializeUserAnswers = useCallback(() => {
    // Return saved answers from cache OR initialize it from scratch using API data
    const cachedTestAnswers =
      JSON.parse(sessionStorage.getItem(sessionStorageKey)) ||
      submittedAnswers ||
      {};
    if (cachedTestAnswers[key]) {
      return cachedTestAnswers[key];
    }
    return Object.values(questions).reduce(
      (acc, question) => ({ ...acc, [question.id]: -1 }),
      {}
    );
  }, [key, questions, submittedAnswers, sessionStorageKey]);

  const [userAnswers, setUserAnswers] = useState(initializeUserAnswers());
  const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
  const [fade, setFade] = useState(false);
  const { t } = useTranslation();
  const questionObj = Object.values(questions)[currentQuestionIdx];
  const {
    id: questionId,
    options,
    optionsValues,
    question,
    // eslint-disable-next-line
    type,
  } = questionObj;
  const totalQuestions = questions.length;
  const isQuestionAnswered = userAnswers[questionId] !== -1;
  const areAllQuestionsAnswered =
    currentQuestionIdx === totalQuestions - 1 &&
    Object.keys(userAnswers).every((question) => userAnswers[question] !== -1);
  // TODO: This useEffect is launching tree times on recharge and twice on next question
  useEffect(() => {
    // Reset state as each new test is loaded
    setUserAnswers(initializeUserAnswers());
    setCurrentQuestionIdx(0);
  }, [key, initializeUserAnswers]);

  const onMcqAnswerSelect = (questionId, optionValues) => {
    const updatedUserAnswers = { ...userAnswers, [questionId]: optionValues };
    setUserAnswers(updatedUserAnswers);
    cacheUserAnswers(updatedUserAnswers);
    if (currentQuestionIdx !== totalQuestions - 1) {
      setTimeout(() => {
        setFade(true);
      }, 200);
      setTimeout(() => {
        showNextQuestion();
        setFade(false);
      }, 500);
    }
  };

  const cacheUserAnswers = (updatedUserAnswers) => {
    const cachedTestAnswers =
      JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {};
    sessionStorage.setItem(
      sessionStorageKey,
      JSON.stringify({
        ...cachedTestAnswers,
        [key]: updatedUserAnswers,
      })
    );
  };

  const showNextQuestion = () => {
    if (currentQuestionIdx < totalQuestions - 1) {
      setCurrentQuestionIdx(currentQuestionIdx + 1);
    } else {
      submitTestAnswers(key, userAnswers);
    }
  };

  const showPreviousQuestion = () => {
    if (currentQuestionIdx > 0) {
      setCurrentQuestionIdx(currentQuestionIdx - 1);
    } else {
      onPrevious();
    }
  };

  return (
    <EvaluationLayout
      onContinue={showNextQuestion}
      onPrevious={showPreviousQuestion}
      isBackBtnDisabled={currentQuestionIdx === 0}
      isNextBtnDisabled={
        currentQuestionIdx === totalQuestions - 1 || !isQuestionAnswered
      }
      isBtnLoading={isSubmittingAnswers}
      isPrimaryBtnDisabled={!areAllQuestionsAnswered}
      primaryBtnText={t("general.submit")}
    >
      <div className="test-step">
        <div className="mcq-card">
          <Text className="description" textStyle={TEXT_STYLES.medium}>
            {description}
          </Text>
          <Mcq
            className={classNames({ hidden: fade })}
            questionId={questionId}
            checkedOption={userAnswers[questionId]}
            question={`${currentQuestionIdx + 1}. ${question}`}
            options={options}
            optionsWeight={optionsValues}
            onChange={onMcqAnswerSelect}
          />
        </div>
        <Text textStyle={TEXT_STYLES.mediumLight}>
          {currentQuestionIdx + 1}/{totalQuestions}
        </Text>
      </div>
    </EvaluationLayout>
  );
};

export default AcademicTestStep;
