import { getReport } from "api/user/report";
import ErrorPage from "components/layout/ErrorPage";
import Loader from "components/layout/Loader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./FinalReport.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import Button from "components/ui/buttons/Button";
import { FinalReportBlock } from "./ReportBlock";
import { EVALUATION_TYPES } from "pages/Evaluation";
import classNames from "classnames";
import { getStoredUserData } from "utils/auth";

const FinalReport = ({ fromTestCompletion, onContinue }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [isError, setIsError] = useState(false);
  const userDetails = getStoredUserData();

  const getFinalReport = useCallback(async () => {
    try {
      const data = userDetails.state === "FINAL_EVALUATION_COMPLETE" && await getReport(EVALUATION_TYPES.FINAL);
      setReportData(data);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem("finalReport"));
    if (cachedData) {
      setReportData(cachedData);
      setLoading(false);
    } else {
      getFinalReport();
    }
  }, [getFinalReport]);

  if (loading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorPage />;
  }

  if (
    !reportData.isReportAvailable ||
    userDetails.state !== "FINAL_EVALUATION_COMPLETE"
  ) {
    return (
      <div className="report-not-available">
        <Text textStyle={TEXT_STYLES.smallLight} tagName="p">
          {reportData.message || t("final_report.not_available")}
        </Text>
      </div>
    );
  }

  return (
    <div className="report-content">
      <Text className="text-uppercase report-title" tagName="h2" textStyle="large" textTransform="uppercase">
        {t("final_report.title")}
      </Text>
      <Text className="intro" textStyle={TEXT_STYLES.smallGrey}>
        {t("final_report.intro")}
      </Text>
      <div className="report-blocks">
        {reportData.report.map((block, index) => (
          <FinalReportBlock className={classNames({ "from-test-completion": fromTestCompletion })} key={index} index={index} data={block} />
        ))}
      </div>
      <Text className="outro" textStyle={TEXT_STYLES.smallLightItalic}>
        {t("final_report.outro")}
      </Text>
      {fromTestCompletion ? (
        <Button className="continue-btn" onClick={onContinue} type="PRIMARY">
          {t("general.continue")}
        </Button>
      ) : null}
    </div>
  );
};

export default FinalReport;
