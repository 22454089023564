import Loader from "components/layout/Loader";
import { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { firebaseAuth } from "services/firebase";
import { getStoredUserData } from "utils/auth";

const PrivateRoute = ({ ...rest }) => {
  const [isAuth, setIsAuth] = useState(null);
  const navigate = useNavigate();
  const userDetails = getStoredUserData();

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
        navigate("/login", { replace: true });
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isAuth === null) {
    return <Loader />;
  }

  if (isAuth === true) {
    if (!userDetails.isEmailVerified) {
      navigate(`/verify-email?email=${userDetails.email}`);
      return;
    }
  }

  return isAuth ? <Outlet {...rest} /> : null;
};

export default PrivateRoute;
