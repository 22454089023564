/* eslint-disable react-hooks/exhaustive-deps */
import "./Video.scss";
import Player from "@vimeo/player";
import {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useState,
} from "react";
import useThrottle from "hooks/useThrottle";
import MoodTracker, { MOOD_TYPE } from "components/ui/MoodTracker";
import Text, { TEXT_STYLES } from "components/ui/Text";
import { useTranslation } from "react-i18next";
import { USER_ACTIVITY_STATUS } from "utils/constants";

const Video = forwardRef(
  (
    {
      id,
      url,
      title,
      status,
      progress,
      isDailyVideo,
      updateVideoProgress,
      updateMood,
      moodBeforeCompletion,
      moodAfterCompletion,
    },
    ref
  ) => {
    const { t } = useTranslation();
    let playerRef = useRef();
    const [beforeMoodRecorded, setBeforeMoodRecorded] = useState(moodBeforeCompletion !== undefined);
    const [afterMoodRecorded, setAfterMoodRecorded] = useState(moodAfterCompletion !== undefined);
    const timeWatched = useRef(0);

    const pause = () => {
      playerRef.current.pause();
    };

    const getProgress = async () => {
      const duration = await playerRef.current.getDuration();
      const currentTime = await playerRef.current.getCurrentTime();
      const percentage = Math.ceil((currentTime / duration) * 100);
      return percentage > 97 ? 100 : percentage;
    };

    const handleTimeUpdate = useThrottle(({ percent, seconds }) => {
      // Reference: https://codepen.io/ctam8/pen/KrzRyg
      if (
        isDailyVideo &&
        seconds - 4 < timeWatched.current &&
        seconds > timeWatched.current
      ) {
        timeWatched.current = seconds;
      }
      const normalizedPercent = percent > 0.97 ? 1 : percent;
      if (normalizedPercent === 1) {
        playerRef.current.off("timeupdate");
      }
      updateVideoProgress(Math.ceil(normalizedPercent * 100), id);
    }, 3000);

    const setCurrentTime = useCallback(async () => {
      if (progress) {
        const duration = await playerRef.current.getDuration();
        playerRef.current.setCurrentTime(duration * (progress / 100));
      }
    }, []);

    useEffect(() => {
      const player = new Player(document.getElementById(`player_${id}`), {
        url,
        pip: true,
        title,
        fullscreen: true,
      });
      playerRef.current = player;
      setCurrentTime();
      player.on("timeupdate", handleTimeUpdate);
      if (isDailyVideo) {
        player.on("seeked", ({ seconds }) => {
          if (timeWatched.current < seconds) {
            player.setCurrentTime(timeWatched.current);
          }
        });
      }
      return () => {
        player.off("timeupdate");
        player.off("seeked");
      };
    }, [id, setCurrentTime]);

    useImperativeHandle(ref, () => ({
      pause,
      getProgress,
    }));

    const recordMood = (moodType, moodScore) => {
      moodType === MOOD_TYPE.BEFORE
        ? setBeforeMoodRecorded(true)
        : setAfterMoodRecorded(true);
      updateMood(id, moodType, moodScore);
    };

    return (
      <div className="player-wrap">
        {status === USER_ACTIVITY_STATUS.LOCKED && (
          <div className="overlay">
            <Text textStyle={TEXT_STYLES.mediumWhite}>
              {isDailyVideo
                ? t("exercise.daily_video_locked")
                : t("exercise.video_locked")}
            </Text>
          </div>
        )}
        {status !== USER_ACTIVITY_STATUS.LOCKED &&
          isDailyVideo &&
          ((progress === 0 && !beforeMoodRecorded) ||
            (progress === 100 && !afterMoodRecorded)) && (
            <div className="overlay">
              <MoodTracker
                moodType={progress === 0 ? MOOD_TYPE.BEFORE : MOOD_TYPE.AFTER}
                recordMood={recordMood}
              />
            </div>
          )}
        <div className="player" id={`player_${id}`}></div>
      </div>
    );
  }
);

export default Video;
