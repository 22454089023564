import { getReport, getAcademicReport } from "api/user/report";
import ErrorPage from "components/layout/ErrorPage";
import Loader from "components/layout/Loader";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStoredUserData } from "utils/auth";
import "./InitialReport.scss";
import Text, { TEXT_STYLES } from "components/ui/Text";
import AnxietyManifest from "static/images/anxiety_manifest.svg";
import AnxietyResponse from "static/images/anxiety_response.svg";
import AnxietyGenerate from "static/images/anxiety_generate.svg";
import AdditionalTip from "static/images/additional_tip.svg";
import TAISlight from "static/images/tai_slight.svg";
import TAIModerated from "static/images/tai_moderated.svg";
import TAIHigh from "static/images/tai_high.svg";
import TAIIntense from "static/images/tai_intense.svg";
import ADHOCGood from "static/images/adHoc_good.svg";
import ADHOCBad from "static/images/adHoc_bad.svg";
import ASAIBefore from "static/images/asai_before.svg";
import ASAIBetween from "static/images/asai_between.svg";
import ASAIAfter from "static/images/asai_after.svg";
import Button from "components/ui/buttons/Button";
import { InitialReportBlock } from "./ReportBlock";

const REPORT_BLOCK_IMAGE_MAP = {
  how_do_stress_anxiety_manifest: AnxietyManifest,
  anxiety_manifestation: AnxietyResponse,
  anxiety_in_situations: AnxietyGenerate,
  anger_management: AdditionalTip,

  IITAILW: TAISlight,
  IITAILE: TAISlight,
  IITAILWE: TAISlight,
  IITAIMW: TAIModerated,
  IITAIME: TAIModerated,
  IITAIMWE: TAIModerated,
  IITAIEW: TAIHigh,
  IITAIEE: TAIHigh,
  IITAIEWE: TAIHigh,
  IITAIIW: TAIIntense,
  IITAIIE: TAIIntense,
  IITAIIWE: TAIIntense,

  II_SUEÑO_1: ADHOCGood,
  II_SUEÑO_2: ADHOCGood,
  II_SUEÑO_3: ADHOCBad,
  II_SUEÑO_4: ADHOCBad,

  II_SIT_SCENARIO_1: ASAIBefore,
  II_SIT_SCENARIO_2: ASAIBefore,
  II_SIT_SCENARIO_3: ASAIBetween,
  II_SIT_SCENARIO_4: ASAIBetween,
  II_SIT_SCENARIO_5: ASAIAfter,
  II_SIT_SCENARIO_6: ASAIAfter,
  II_SIT_SCENARIO_7: ASAIBetween,
  II_SIT_SCENARIO_8: ASAIAfter,
  II_SIT_SCENARIO_9: ASAIBetween,
  II_SIT_SCENARIO_10: ASAIBetween,
};

const InitialReport = ({ fromOnBoarding, onContinue }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [isError, setIsError] = useState(false);
  const userDetails = getStoredUserData();
  const userRole = userDetails.role;

  const getInitialReport = useCallback(async () => {
    try {
      const data =
        userRole === "user"
          ? await getReport("Initial")
          : parseAcademicReport(await getAcademicReport("initial-reports"));
      setReportData(data);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const parseAcademicReport = (data) => {
    const report = data.initialReport;

    const parsedReport = {
      report: [
        {
          id: report.adHocReport?.scenario || "",
          title: t("initial_report.adHocReport.title"),
          content:
            t("initial_report.adHocReport.introduction") +
            " " +
            t(
              `initial_report.adHocReport.scenarios.${report.adHocReport?.scenario || ""}`
            ),
        },
        {
          id: report.asaiReport?.scenario || "",
          title: t("initial_report.asaiReport.title"),
          content:
            t("initial_report.asaiReport.introduction") +
            " " +
            t(
              `initial_report.asaiReport.scenarios.${report.asaiReport?.scenario || ""}`
            ),
        },
        {
          id: report.taiReport?.scenario || "",
          title: t("initial_report.taiReport.title"),
          content:
            t("initial_report.taiReport.introduction") +
            " " +
            t(
              `initial_report.taiReport.scenarios.${report.taiReport?.scenario || ""}`
            ),
        },
      ],
    };

    return parsedReport;
  };

  useEffect(() => {
    const cachedData = JSON.parse(sessionStorage.getItem("initialReport"));
    if (cachedData) {
      setReportData(cachedData);
      setLoading(false);
    } else {
      getInitialReport();
    }
  }, [getInitialReport]);

  if (loading) {
    // Display a loader while data is being fetched
    return <Loader />;
  }
  if (isError) {
    return <ErrorPage />;
  }

  if (
    !reportData.isReportAvailable &&
    reportData.isReportAvailable !== undefined
  ) {
    return (
      <div className="report-not-available">
        <Text textStyle={TEXT_STYLES.smallLight} tagName="p">
          {reportData.message}
        </Text>
      </div>
    );
  }

  return (
    <div className="report-content">
      <div className="report-title">
        <Text
          className="text-uppercase"
          tagName="span"
          textStyle="large"
          textTransform="uppercase"
        >
          {t("initial_report.title")}
        </Text>
      </div>
      <div className="greeting-block">
        <Text tagName="span" textStyle={TEXT_STYLES.smallGrey}>
          {t("initial_report.hello")}{" "}
        </Text>
        <Text tagName="span" textStyle={TEXT_STYLES.medium}>
          {`${userDetails.displayName},`}
        </Text>
      </div>
      <Text textStyle={TEXT_STYLES.medium}>
        {t("initial_report.description_header")}
      </Text>
      <div className="initial-description-block">
        <Text textStyle={TEXT_STYLES.smallGrey}>
          {t("initial_report.description")}
        </Text>
      </div>
      <div className="report-blocks">
        {reportData.report.map((block, index) => (
          <InitialReportBlock
            key={index}
            blockTitle={block.title}
            blockImage={REPORT_BLOCK_IMAGE_MAP[block.id]}
            blockDescription={block.content}
          />
        ))}
      </div>
      <Text
        className="final-description-block"
        textStyle={TEXT_STYLES.smallGrey}
      >
        {t("initial_report.final_description")}
      </Text>
      <Text className="disclaimer-title" textStyle={TEXT_STYLES.small}>
        {t("initial_report.disclaimer_title")}
      </Text>
      <Text className="disclaimer" textStyle={TEXT_STYLES.smallGrey}>
        {t("initial_report.disclaimer")}
      </Text>
      <Text className="warning" textStyle={TEXT_STYLES.smallLightItalic}>
        {t("initial_report.warning")}
      </Text>
      <Text
        className="text-uppercase good-luck"
        textStyle={TEXT_STYLES.largeLightItalic}
      >
        {t("initial_report.good_luck")}
      </Text>
      {fromOnBoarding ? (
        <Button className="continue-btn" onClick={onContinue} type="PRIMARY">
          {t("general.continue")}
        </Button>
      ) : null}
    </div>
  );
};

export default InitialReport;
