import Text, { TEXT_STYLES } from "components/ui/Text";
import "./ReportBlock.scss";
import ExpandableCard from "components/ui/ExpandableCard";
import Separator from "components/ui/Separator";
import { useTranslation } from "react-i18next";
import Chart from "./Chart";
import useChartData from "hooks/useChartData";

const InitialReportBlock = ({ blockTitle, blockImage, blockDescription }) => {
  return (
    <ExpandableCard title={blockTitle}>
      <Separator className="divider-horizontal" />
      <div className="block-image">
        <img
          src={blockImage}
          alt="ReportBlockImage"
          style={{ aspectRatio: "16/9", width: "100%" }}
        ></img>
      </div>
      <Text
        className="report-block-text"
        textStyle={TEXT_STYLES.smallGrey}
        setInnerHtmlDangerously
      >
        {blockDescription}
      </Text>
    </ExpandableCard>
  );
};

const FinalReportBlock = ({ data, className, index }) => {
  const { title, sections, chart, description, result } = data;
  const { chartData, bars } = useChartData(chart);
  return (
    <ExpandableCard className={className} title={`${index + 1}. ${title}`}>
      <Separator className="divider-horizontal" />
      <Description text={description} />
      <div className="chart">
        <Chart data={chartData}>{bars}</Chart>
      </div>
      <Separator className="divider-horizontal" />
      {sections && (
        <div className="sections">
          {sections.map((section, idx) => (
            <>
              <div className="section">
                <Text
                  tagName="h4"
                  className="section-title"
                  textStyle={TEXT_STYLES.smallDark}
                >
                  {section.title}
                </Text>
                <Description text={section.description} />
                <Result text={section.result} />
              </div>
              {idx !== sections.length - 1 && <Separator />}
            </>
          ))}
        </div>
      )}
      {result && <Result text={result} />}
    </ExpandableCard>
  );
};

const Description = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Text textStyle={TEXT_STYLES.smallTeal}>{t("general.description")}</Text>
      <Text
        tagName="p"
        className="report-block-text"
        textStyle={TEXT_STYLES.smallGrey}
        setInnerHtmlDangerously
      >
        {text}
      </Text>
    </div>
  );
};

const Result = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Text textStyle={TEXT_STYLES.smallTeal}>{t("general.result")}</Text>
      <Text
        tagName="p"
        className="report-block-text"
        textStyle={TEXT_STYLES.smallGrey}
        setInnerHtmlDangerously
      >
        {text}
      </Text>
    </div>
  );
};

export { InitialReportBlock, FinalReportBlock };
