import { firebaseAuth } from "services/firebase";
import { get, patch, post } from "utils/network";

async function getProgramSummary() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-program-summary`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getAcademicProgramSummary() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/academic/summary/${userId}`,
        params: { prefLang: "es" },
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getAllWeeksProgram() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-weekly-program`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getAllWeeksAcademicProgram(weekId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/academic/program-progress/${userId}/weeks`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getSingleWeekProgram(weekId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-single-week-program`,
        headers: { Authorization: `Bearer ${token}` },
        params: { week_id: weekId },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getSingleWeekAcademicProgram(weekId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/academic/program-progress/${userId}/weeks/${weekId}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getModule(weekId, moduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-module`,
        headers: { Authorization: `Bearer ${token}` },
        params: { week_id: weekId, module_id: moduleId },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function getConclusionModule() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/get-conclusion-module`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function completeConclusionModule() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/complete-conclusion-module`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateProgress(weekId, moduleId, submoduleId, progress) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-progress`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          week_id: weekId,
          module_id: moduleId,
          submodule_id: submoduleId,
          progress,
        }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateAcademicProgress(submoduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await patch({
        url: `${process.env.REACT_APP_API_SERVER}/academic/program-progress/${userId}/complete-submodule/${submoduleId}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateEllisData(weekId, moduleId, submoduleId, userSelections) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-ellis-data`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          week_id: weekId,
          module_id: moduleId,
          submodule_id: submoduleId,
          user_selections: userSelections,
        }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateAcademicEllisData(submoduleId, userSelections, ellis) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await patch({
        url: `${process.env.REACT_APP_API_SERVER}/academic/program-progress/${userId}/submodule/${submoduleId}/ellis/${ellis}`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          situation: userSelections.selectedSituation,
          emotion: userSelections.selectedEmotion,
          thought: userSelections.selectedThought,
          alternativeThought: userSelections.selectedAlternativeThought,
          comment: userSelections.userTextInput,
        }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateMood(weekId, moduleId, submoduleId, moodType, moodScore) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/update-mood`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          week_id: weekId,
          module_id: moduleId,
          submodule_id: submoduleId,
          type: moodType,
          mood_score: moodScore,
        }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function updateAcademicMood(submoduleId, moodType, moodScore) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await patch({
        url: `${process.env.REACT_APP_API_SERVER}/academic/program-progress/${userId}/submodule/${submoduleId}/mood`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ type: moodType, score: moodScore }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function setMcqPassed(weekId, moduleId, submoduleId) {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/set-mcq-passed`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          week_id: weekId,
          module_id: moduleId,
          submodule_id: submoduleId,
        }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

export {
  getProgramSummary,
  getAcademicProgramSummary,
  getAllWeeksProgram,
  getAllWeeksAcademicProgram,
  updateEllisData,
  updateAcademicEllisData,
  getModule,
  getConclusionModule,
  updateMood,
  updateAcademicMood,
  completeConclusionModule,
  getSingleWeekProgram,
  getSingleWeekAcademicProgram,
  updateProgress,
  updateAcademicProgress,
  setMcqPassed,
};
