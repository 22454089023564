import InitialReport from 'components/report/InitialReport';
import ConfirmationDialog from 'components/evaluation/ConfirmationDialog';
import TestStep from 'components/evaluation/TestStep';
import AcademicTestStep from 'components/evaluation/AcademicTestStep';
import TncStep from 'components/evaluation/TncStep';
import VideoStep from 'components/evaluation/VideoStep';
import Summary from 'components/program/Summary';
import FinalReport from 'components/report/FinalReport';
import translator from 'services/translator';
import RelaxationVideoStep from 'components/evaluation/RelaxationVideoStep';

const tos = `
    <div class="tos-text-content">
    En esta página web se cuida y se vela por respetar los datos personales de
los usuarios. Como usuario, debe saber que sus derechos están garantizados.
Nuestro equipo ha hecho sus mejores esfuerzos en crear un espacio seguro y
confiable para usted.
Aquí le explicamos qué datos personales recopilamos de nuestros usuarios y
cómo se utilizan.
<br>
<br>
Neuromify S.L. ha adecuado esta web a las exigencias de la Ley Orgánica
15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal
(LOPD), y al Real Decreto 1720/2007, de 21 de diciembre, conocido como el
Reglamento de desarrollo de la LOPD. Cumple también con el Reglamento
(UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016
relativo a la protección de las personas físicas, así como con la Ley 34/2002,
de 11 de julio, de Servicios de la Sociedad de la Información y Comercio
Electrónico (LSSI).
<br>
<br>
<h3><b>1. DATOS IDENTIFICATIVOS</b></h3>

Con el fin de dar cumplimiento al artículo 10 de la LSSI, informamos a los
usuarios de nuestros datos identificativos y de contacto:
<br>
<ul>
<li><b>Nombre o Denominación social:</b> Neuromify S.L.</li>
<li><b>Domicilio social:</b> C/Cercis Nº30 28023 Madrid</li>
<li><b>NIF:</b> B44884922</li>
<li><b>Teléfono:</b> (+34) 682 157 450</li>
<li><b>E-Mail:</b> neuromify@gmail.com</li>
<li><b>Datos Registrales:</b> Esta sociedad consta inscrita en el tomo 45019,</li>
Folio 51, Hoja número M-792361, inscripción 1ª, del Registro
Mercantil de Madrid,
<li><b>Actividad:</b> Actividades de programación informática.</li>
</ul>
<br>
<h3><b>2.- CONDICIONES DE UTILIZACIÓN DE LA PÁGINA WEB Y POLÍTICA DE PRIVACIDAD</b></h3>

A través de este sitio web, Neuromify S.L proporcionará a los usuarios,
herramientas para ayudar a la disminución de los síntomas del estrés y la
ansiedad no clínicos.
<br><br>
Principios que aplicarán a la información personal
<br><br>
En el tratamiento de sus datos personales, aplicaremos los siguientes
principios:
<ul>
<li>Principio de licitud, lealtad y transparencia: Siempre vamos a
requerir su consentimiento para el tratamiento de sus datos personales
para uno o varios fines específicos que le informaremos previamente
con absoluta transparencia.</li>
<li>Principio de minimización de datos: Solo solicitaremos datos
estrictamente necesarios en relación con los fines para los que los
requerimos.</li>
<li>Principio de limitación del plazo de conservación: los datos serán
mantenidos durante el tiempo necesario para los fines del tratamiento,
en función a la finalidad que se busque.</li>
<li>Principio de integridad y confidencialidad: Sus datos serán tratados
de tal manera que se garantice una seguridad y confidencialidad
adecuada de los datos personales.</li>
</ul>
<br>
Los datos personales que tratamos proceden única y exclusivamente del
proceso de registro de la cuenta de cada usuario y la respuesta de los
cuestionarios habilitados en dicho proceso, y, por otro lado, de la solicitud de
información comercial.
<br><br>
<b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b>
<br><br>
El titular de la información personal brindada a través de esta página web se
denomina “interesado”.
<br><br>
Cualquier interesado tiene derecho a obtener confirmación sobre si en esta
página web, Neuromify S.L. está tratando sus datos personales.
<br><br>
Las personas interesadas tienen derecho a:
<ul>
<li>Solicitar el acceso a los datos personales relativos al interesado;</li>
<li>Solicitar su rectificación o supresión;</li>
<li>Solicitar la limitación de su tratamiento;</li>
<li>Oponerse al tratamiento, y</li>
<li>Solicitar la portabilidad de los datos.</li>
</ul>
<br>
Los interesados podrán acceder a sus datos personales, así como solicitar
la rectificación de los datos inexactos o, en su caso, solicitar
su supresión cuando, entre otros motivos, los datos ya no sean necesarios
para los fines que fueron recogidos. En determinadas circunstancias, los
interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo
caso únicamente serán conservados para el ejercicio o la defensa de
reclamaciones.
<br><br>
En determinadas circunstancias y por motivos relacionados con su situación
particular, los interesados podrán oponerse al tratamiento de sus datos. Desde
Neuromify S.L., dejaremos de tratar los datos, salvo por motivos legítimos
imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
<br><br>
Como interesado, tiene derecho a recibir los datos personales que le incumban,
que nos haya facilitado y en un formato estructurado, de uso común y lectura
mecánica, y a transmitirlos a otro responsable del tratamiento, cuando:
<br><br>
<ul>
<li>El tratamiento esté basado en el consentimiento;</li>
<li>Los datos hayan sido facilitados por la persona interesada; o</li>
<li>El tratamiento se efectúe por medios automatizados.</li>
</ul>
<br>
Al ejercer su derecho a la portabilidad de los datos, tendrá derecho a que los
datos personales se transmitan directamente de responsable a responsable
cuando sea técnicamente posible.
<br><br>
<b>¿Con qué finalidad tratamos sus datos personales?</b>
<br><br>
En Neuromify S.L. existen diferentes sistemas de captura de información
personal y trato de la información que facilitan las personas interesadas con
cualquiera de las siguientes finalidades, y por cada sistema de captura
(formularios):
<br><br>
- Solicitud de creación de usuario para participación en el programa, y
<br><br>
En caso de participación en el programa los datos serán tratados con los
siguientes fines:
<br><br>
- <b>Información al usuario:</b> se elaboran de manera automatiza informes a
partir de las respuestas a los cuestionarios. Se ofrecerán herramientas
de gestión emocional. Desde, Neuromify S.L. no podremos acceder a las
respuestas individuales de cada usuario. El tratamiento será
automatizado, sin intermediación humana. Al facilitar su información, el
usuario da su consentimiento para que su información sea recopilada,
utilizada, gestionada y almacenada por Neuromify S.L., sólo como se
describe en la presente Política de Privacidad.
<br><br>
- <b>Información al empleador:</b> La empresa recibirá unos reportes
anonimizados por grupo y sector, sin individualizar al titular de los datos.
La finalidad es comprender la dinámica de cada departamento y
categoría.
<br><br>
El empleador del interesado también podrá ver el porcentaje de progreso
de cada usuario y podrá acceder a los resultados del sistema de puntaje
(scoring) por participación, sin acceder a las respuestas individuales de
los cuestionarios.
<br><br>
- <b>Información a los investigadores de la universidad:</b> Se cederá
información anonimizada, la cual será cargada a un sistema de análisis
estadístico para realizar un informe comparativo y encontrar
correlaciones.
<br><br>
De acuerdo con lo establecido en el Reglamento (UE) 2016/679 del Parlamento
Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las
personas físicas , Neuromify S.L. será responsable del tratamiento de los
datos.
<br><br>
Neuromify S.L. no vende, alquila ni cede datos de carácter personal que
puedan identificar al usuario, ni lo hará en el futuro, a terceros sin el
consentimiento previo.
<br><br>
En caso de realizar alguna colaboración, vamos a requerir su consentimiento,
informándole sobre la identidad del colaborador y la finalidad de la
colaboración. Siempre se realizará con los más estrictos estándares de
seguridad y confidencialidad.
<br><br>
<b>Legitimación para el tratamiento de sus datos</b>
<br><br>
La base legal para el tratamiento de sus datos es el consentimiento. Para
contactar o crear su usuario en esta web, se requiere el previo consentimiento
de esta Política de Privacidad.
<br><br>
<b>Categoría de datos recogidos</b>
<br><br>
Las categorías de datos que se tratan son datos especialmente protegidos. Sin
embargo, el volumen del tratamiento no es de gran escala.
<br><br>
<b>¿Por cuánto tiempo conservaremos sus datos?</b>
<br><br>
Conservaremos sus datos hasta que cumplan los fines establecidos en esta
política o hasta que nos solicite su supresión.
<br><br>
<b>¿A qué destinatario se comunicarán sus datos?</b>
<br><br>
En caso de participación en el programa, los datos serán comunicados al
<b>empleador</b> de los interesados. Este podrá visualizar los resultados de manera
agregada y anonimizada. Por ejemplo: “El 70% del departamento de
producción presenta un nivel elevado de estrés”.
<br><br>
Por otro lado, realizamos una colaboración con una universidad de reconocido
prestigio, proporcionado información completamente anonimizada, sin ningún
tipo de vínculo con los datos del usuario. Esto se hará con fines de
investigación y los datos se cargarán en un software estadístico de la
Universidad para analizar correlaciones.
<br><br>
<b>Seguridad de los datos</b>
<br><br>
Neuromify S.L. se compromete en el uso y tratamiento de los datos personales
de los usuarios, respetando su confidencialidad para utilizarlos de acuerdo con
la finalidad de la sociedad, así como a dar cumplimiento a su obligación de
guardarlos y adaptar todas las medidas para evitar la alteración, pérdida,
tratamiento o acceso no autorizado, de conformidad con lo establecido en la
normativa vigente de protección de datos.
<br><br>
En caso de presentarse algún incidente de seguridad, al advertirlo, Neuromify
S.L., notificará a los interesados sin demoras indebidas y proporcionará
información oportuna relacionada con el Incidente de Seguridad tal como se
conozca o cuando el interesado lo solicite razonablemente.
<br><br>
Exclusión de garantías y cuestiones sobre la responsabilidad
<br><br>
Este sitio web ha sido revisado y probado para que funcione correctamente. Sin
embargo, Neuromify S.L. no descarta la posibilidad de que existan
<br><br>
determinados errores de programación, o que sucedan causas de fuerza mayor
que hagan imposible de forma temporal el acceso a la página web.
<br><br>
El Neuromify S.L. excluye, hasta donde permite el ordenamiento jurídico,
cualquier responsabilidad por los daños y perjuicios de toda naturaleza
derivados de:
<br><br>
1. La <b>imposibilidad de acceso</b> al sitio web o la falta de veracidad,
exactitud, exhaustividad y/o actualidad de los contenidos, así como la
existencia de vicios y defectos de toda clase de los contenidos
transmitidos, difundidos, almacenados, puestos a disposición a los que
se haya accedido a través del sitio web o de los servicios que se
ofrecen.<br>
2. El <b>incumplimiento</b> de las leyes, la buena fe, el orden público, los usos
del tráfico y el presente Aviso legal, como consecuencia del uso
incorrecto del sitio web.
<br><br>
El prestador no garantiza la disponibilidad continua y permanente de los
servicios, quedando de este modo exonerado de cualquier responsabilidad por
posibles daños y perjuicios causados como consecuencia de la falta de
disponibilidad del servicio por motivos de fuerza mayor o errores en las redes
telemáticas de transferencia de datos ajenos a su voluntad, o por
desconexiones realizadas por trabajos de mejora o mantenimiento de los
equipos y sistemas informáticos.
<br><br>
<b>Cookies</b>
<br><br>
Este sitio web utiliza cookies que almacenan y recuperan información cuando
navegas para reconocerte como usuario. Las cookies se guardan en la cache
de su browser de usuario, el directorio depende del browser que se trate. No
utilizamos cookies con fines publicitarios.
</div>
    `;

const GENDERS = [
  { label: 'general.male', value: 'Male' },
  { label: 'general.female', value: 'Female' },
  { label: 'general.nonBinary', value: 'Other' }
]

const EVALUATION_STEPS = [
  {
    id: 'how_does_it_work',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/917075929?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    downloadBtnText: 'evaluation.how_the_program_works',
    fileName: `${translator.t('evaluation.how_the_program_works')}.pdf`,
    downloadUrl:
      'https://firebasestorage.googleapis.com/v0/b/neuromify-a044c.appspot.com/o/Docs%2FFuncionamiento%20del%20programa.pdf?alt=media&token=ef725ea3-111e-4400-b395-62fb688080ee',
    ViewComponent: VideoStep
  },
  {
    id: 'tnc',
    type: 'TNC',
    containerClass: 'text-view',
    primaryBtnText: 'Aceptar',
    ViewComponent: TncStep
  },
  {
    id: 'how_to',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/831065415?h=7fae8511ed&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    ViewComponent: VideoStep,
    OnContinueDialog: ConfirmationDialog
  },
  {
    id: 'test_1a',
    key: '1A',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_1b',
    key: '1B',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_2',
    key: '2',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_3',
    key: '3',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_4',
    key: '4',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  { id: 'initial_report', type: 'REPORT', ViewComponent: InitialReport },
  {
    id: 'personalised_program',
    type: 'PERSONALISED_PROGRAM',
    ViewComponent: Summary
  }
]

const FINAL_EVALUATION_STEPS = [
  {
    id: 'how_to',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/831065415?h=7fae8511ed&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    ViewComponent: VideoStep,
    OnContinueDialog: ConfirmationDialog
  },
  {
    id: 'test_1a',
    key: '1A',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_1b',
    key: '1B',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_2',
    key: '2',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_3',
    key: '3',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  {
    id: 'test_4',
    key: '4',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: TestStep
  },
  { id: 'final_report', type: 'REPORT', ViewComponent: FinalReport }
]

const ACADEMIC_EVALUATION_STEPS = [
  {
    id: 'confidienciality',
    key: 'confidienciality',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/1048378768?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    ViewComponent: VideoStep
  },
  {
    id: 'how_does_it_work',
    key: 'how_does_it_work',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/917075929?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    downloadBtnText: 'evaluation.how_the_program_works',
    fileName: `${translator.t('evaluation.how_the_program_works')}.pdf`,
    downloadUrl:
      'https://firebasestorage.googleapis.com/v0/b/neuromify-a044c.appspot.com/o/Docs%2FFuncionamiento%20del%20programa.pdf?alt=media&token=ef725ea3-111e-4400-b395-62fb688080ee',
    ViewComponent: VideoStep
  },
  {
    id: 'how_to_respond_tests',
    key: 'how_to_respond_tests',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/831065415?h=7fae8511ed&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    ViewComponent: VideoStep,
    OnContinueDialog: ConfirmationDialog
  },
  {
    id: 'test_TAI',
    key: 'TAI',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: AcademicTestStep
  },
  {
    id: 'test_GAD7',
    key: 'GAD7',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: AcademicTestStep
  },
  {
    id: 'test_PSS14',
    key: 'PSS14',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: AcademicTestStep
  },
  {
    id: 'test_ADHOC',
    key: 'ADHOC',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: AcademicTestStep
  },
  {
    id: 'test_ASAI',
    key: 'ASAI',
    type: 'TEST',
    containerClass: 'text-view',
    ViewComponent: AcademicTestStep
  },
  { id: 'initial_report', type: 'REPORT', ViewComponent: InitialReport },
  {
    id: 'personalised_program',
    type: 'PERSONALISED_PROGRAM',
    ViewComponent: Summary
  },
  {
    id: 'post_summary_video',
    key: 'post_summary_video',
    type: 'VIDEO',
    urls: {
      progressive: 'https://player.vimeo.com/video/1048380078?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      autogen: 'https://player.vimeo.com/video/1048380359?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
    },
    ViewComponent: RelaxationVideoStep
  },
  {
    id: 'habits',
    key: 'habits',
    type: 'VIDEO',
    url: 'https://player.vimeo.com/video/1048378732?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    ViewComponent: VideoStep
  }
];

const ACADEMIC_FINAL_EVALUATION_STEPS = []

const USER_STATES = {
  INITIAL_TEST_PENDING: 'INITIAL_TEST_PENDING',
  INITIAL_TEST_IN_PROGRESS: 'INITIAL_TEST_IN_PROGRESS',
  INITIAL_TEST_COMPLETE: 'INITIAL_TEST_COMPLETE',
  PROGRAM_COMPLETE: 'PROGRAM_COMPLETE',
  FINAL_TEST_IN_PROGRESS: 'FINAL_TEST_IN_PROGRESS',
  FINAL_TEST_COMPLETE: 'FINAL_TEST_COMPLETE',

  INITIAL_EVALUATION_PENDING: 'INITIAL_EVALUATION_PENDING',
  INITIAL_EVALUATION_STARTED: 'INITIAL_EVALUATION_STARTED',
  INITIAL_EVALUATION_COMPLETED: 'INITIAL_EVALUATION_COMPLETED',
  FINAL_EVALUATION_STARTED: 'FINAL_EVALUATION_STARTED',
  FINAL_EVALUATION_COMPLETED: 'FINAL_EVALUATION_COMPLETED'
}

const SUPPORTED_LANGUAGES = ['en', 'es']
const WHATSAPP_LINK = 'https://wa.me/603641729'
const DEFAULT_LANGUAGE = 'es'
const SUBMODULE_TYPES = {
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  DOC: 'DOC',
  ELLIS_EXERCISE: 'ELLIS_EXERCISE',
  MCQ: 'MCQ'
}
const USER_ACTIVITY_STATUS = {
  RUNNING: 'RUNNING',
  COMPLETED: 'COMPLETED',
  LOCKED: 'LOCKED'
}
const MODULE_TYPES = {
  PSYCHOEDUCATION: 'PSYCHOEDUCATION',
  RELAXATION: 'RELAXATION',
  MIXED: 'MIXED',
  COMPOSITE_RELAXATION: 'COMPOSITE_RELAXATION',
  CONCLUSION: 'CONCLUSION'
}
const SUPPORT_VIDEO_URL =
  'https://player.vimeo.com/video/917075929?badge=0&autopause=0&player_id=0&app_id=58479'
const PROGRAM_OPERATION_URL =
  'https://firebasestorage.googleapis.com/v0/b/neuromify-a044c.appspot.com/o/Docs%2FFuncionamiento%20del%20programa.pdf?alt=media&token=ef725ea3-111e-4400-b395-62fb688080ee'
const CONCLUSION_VIDEO_URL =
  'https://player.vimeo.com/video/915216407?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
const SUBMODULE_MCQ_COUNT = 2

const USER_ROLE = {
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
  CANDIDATE: 'candidate',
  UNIVERSITY: 'university',
  EBAU: 'ebau'
}

const FAQ_LIST = [
  {
    question: '¿Es Neuromify adecuado para mí?',
    answer:
      'Neuromify está diseñado para ayudar a personas que experimentan sintomas de estrés y ansiedad. Si enfrentas desafíos relacionados con el estrés y la ansiedad y estás buscando un enfoque personalizado y basado en evidencia científica para mejorar tu bienestar emocional, Neuromify puede ser adecuado para ti. Sin embargo, si tienes problemas de salud mental graves, te recomendamos que consultes con un profesional de salud mental.'
  },
  {
    question: '¿Qué áreas principales abarca el programa?',
    answer:
      'Neuromify consta de dos áreas principales que debes realizar en paralelo: módulos de psicoeducación y videos diarios de relajación.'
  },
  // {
  //   question:
  //     '¿Tendrá la empresa acceso a los resultados de mis pruebas psicológicas?',
  //   answer:
  //     'No, la empresa nunca tendrá acceso a los resultados de tus pruebas psicológicas individualizadas. Neuromify cumple en todo momento con la Ley de Protección de Datos para salvaguardar tu confidencialidad.'
  // },
  {
    question: '¿Es seguro compartir mis datos personales con Neuromify?',
    answer:
      'La privacidad y seguridad de tus datos personales es fundamental para Neuromify. La aplicación utiliza avanzadas medidas de seguridad para proteger la información del usuario y garantizar que tus datos personales se mantengan seguros y confidenciales.'
  },
  {
    question: '¿Para qué se utilizarán mis datos?',
    answer:
      'Tus datos serán utilizados para proporcionarte un servicio personalizado a lo largo del programa. Nos adherimos en todo momento a la Ley de Protección de Datos para salvaguardar tu confidencialidad.'
  },
  {
    question: '¿Cuánto tiempo dura el programa Neuromify?',
    answer:
      'El programa está diseñado para durar 6 semanas. Se recomienda dedicar unos 15 minutos diarios al programa.'
  },
  {
    question: '¿Puedo usar Neuromify en varios dispositivos?',
    answer:
      'Sí, podrás acceder a tu cuenta de Neuromify y a tu progreso en diferentes dispositivos siempre y cuando inicies sesión con la misma cuenta de usuario.'
  },
  {
    question:
      '¿Qué tipo de contenido puedo esperar en los módulos de psicoeducación?',
    answer:
      'Los módulos incluyen videos cortos de psicoeducación de la ansiedad y el estrés, estilo de vida, inteligencia emocional, cambio de pensamiento, resiliencia, psicología positiva, entre otros. Además, podrás acceder a resúmenes y contenido descargable a lo largo del programa.'
  },
  {
    question:
      '¿Necesito experiencia previa en técnicas de relajación o terapia cognitivo-conductual para usar Neuromify?',
    answer:
      'No, Neuromify está diseñado para ser accesible tanto para principiantes como para aquellos con experiencia previa en técnicas de relajación o terapia cognitivo-conductual. La aplicación te guiará a través de los conceptos y técnicas necesarios para ayudarte a manejar y reducir el estrés y la ansiedad.'
  },
  {
    question:
      '¿Cuántas veces debo practicar las técnicas de relajación por semana?',
    answer:
      'Debes practicar cada una de las técnicas de relajación al menos 5 días a la semana. La práctica constante de estas técnicas es esencial para garantizar la eficacia del programa.'
  },
  {
    question: '¿Puedo saltarme algunos módulos o videos de relajación?',
    answer:
      'No, es importante seguir el orden establecido y completar todos los módulos y videos de relajación para realizar el programa. Esto se hace para asegurar la efectividad del mismo.'
  },
  {
    question: '¿Puedo acceder a los módulos de semanas anteriores?',
    answer:
      'Sí, la plataforma te permitirá acceder a los módulos programados para la semana actual y todos los anteriores.'
  },
  {
    question: '¿Cuánto tiempo tardaré en ver resultados con Neuromify?',
    answer:
      'Los resultados pueden variar según el individuo y su constancia en la utilización del programa. Dedicando 15 minutos diarios es probable que experimentes mejoras en tus niveles de estrés y ansiedad durante las primeras semanas. Sin embargo, es importante recordar que el manejo del estrés es un proceso continuo y que los resultados pueden variar.'
  },
  {
    question:
      '¿Puedo usar Neuromify junto con otras formas de terapia o tratamiento?',
    answer:
      'Sí, Neuromify puede ser utilizado en conjunto con otras formas de terapia o tratamiento para elestrés y la ansiedad. Sin embargo, te recomendamos que consultes con un profesional de saludmental antes de combinar Neuromify con otros tratamientos.'
  },
  {
    question:
      '¿Qué sucede si no puedo completar el programa en el tiempo asignado?',
    answer:
      'El programa Neuromify está diseñado para completarse en un mínimo de 6 semanas y unmáximo de 8 semanas. Si no puedes completarlo en este período de tiempo, te recomendamosque te pongas en contacto con el equipo de soporte de Neuromify para discutir tus opciones y posibles ajustes en el programa.'
  },
  {
    question:
      '¿Neuromify ofrece soporte al cliente si tengo preguntas o problemas con la aplicación?',
    answer:
      'Sí, Neuromify ofrece soporte al cliente para ayudarte con cualquier pregunta o problema que puedas tener con la aplicación. Puedes ponerte en contacto con el equipo de soporte a través de la aplicación o por correo electrónico y recibirás asistencia en un plazo razonable.'
  },
  {
    question:
      '¿Puedo compartir mi progreso en Neuromify con mi terapeuta o médico?',
    answer:
      'Sí, al finalizar el programa, podrás acceder a un informe final personalizado que mostrará cómo han variado tus niveles de estrés y ansiedad. Este informe puede ser compartido con tu terapeuta o médico para discutir tus avances y planificar futuros tratamientos si es necesario.'
  },
  {
    question: '¿Qué sucede al finalizar el programa Neuromify?',
    answer:
      'Al completar el programa y los tests de evaluación psicológica finales, recibirás un informe personalizado que muestra cómo han variado tus niveles de estrés y ansiedad durante el periodo de realización del programa. Además, tendrás acceso a unas sugerencias para mantener tu bienestar después del programa.'
  }
]

const TERMS_AND_CONDITIONS =
  'https://firebasestorage.googleapis.com/v0/b/compelling-moon-443010-h1.firebasestorage.app/o/Neuromify%20-%20Poli%CC%81tica%20de%20privacidad%20programa%20acade%CC%81mico.pdf?alt=media&token=56003788-db2c-4081-a7b3-be866717d53e'
const PRIVACY_POLICY_LINK =
  'https://firebasestorage.googleapis.com/v0/b/compelling-moon-443010-h1.firebasestorage.app/o/Neuromify%20-%20Te%CC%81rminos%20y%20condiciones.pdf?alt=media&token=d60af336-fff4-4ddf-b8b8-48e1d5d6fd0f'
const SWEEPSTAKES_TERMS_AND_CONDITIONS =
  'https://firebasestorage.googleapis.com/v0/b/compelling-moon-443010-h1.firebasestorage.app/o/Neuromify%20-%20Bases%20y%20condiciones%20del%20sorteo.pdf?alt=media&token=ed910ae1-7f67-44a5-a2c5-a590dd7820a5'

export {
  EVALUATION_STEPS,
  ACADEMIC_EVALUATION_STEPS,
  tos,
  USER_STATES,
  SUPPORT_VIDEO_URL,
  PROGRAM_OPERATION_URL,
  USER_ACTIVITY_STATUS,
  SUBMODULE_TYPES,
  MODULE_TYPES,
  USER_ROLE,
  GENDERS,
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE,
  SUBMODULE_MCQ_COUNT,
  FINAL_EVALUATION_STEPS,
  ACADEMIC_FINAL_EVALUATION_STEPS,
  CONCLUSION_VIDEO_URL,
  FAQ_LIST,
  WHATSAPP_LINK,
  PRIVACY_POLICY_LINK,
  TERMS_AND_CONDITIONS,
  SWEEPSTAKES_TERMS_AND_CONDITIONS
}
