import { firebaseAuth } from "services/firebase";
import { get, post } from "utils/network";

async function fetchTests() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/test-data`,
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function fetchAcademicTests() {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/content/tests`,
        params: { prefLang: "es" },
        headers: { Authorization: `Bearer ${token}` },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function submitTest(testId, answers, testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/user/answer-test`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ answers, testId, testType, overwrite: true }),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function submitAcademicTest(testId, answers, testType = "initial-test") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await post({
        url: `${process.env.REACT_APP_API_SERVER}/academic/initial-evaluations/${testId.toLowerCase()}`,
        headers: { Authorization: `Bearer ${token}` },
        body: JSON.stringify({ userId, answers}),
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function generateReportAndProgram(testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/generate-report-and-program`,
        headers: { Authorization: `Bearer ${token}` },
        params: { type: testType },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function fetchSubmittedAnswers(testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/user/test-answers-data`,
        headers: { Authorization: `Bearer ${token}` },
        params: { testType },
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  });
}

async function fetchAcademicSubmittedAnswers(testType = "initial") {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await firebaseAuth.currentUser.getIdToken();
      const userId = firebaseAuth.currentUser.uid;
      const response = await get({
        url: `${process.env.REACT_APP_API_SERVER}/academic/${testType}-evaluations/${userId}`,
        headers: { Authorization: `Bearer ${token}` }
      });
      resolve(response);
    } catch (error) {
      console.error(error);
      reject(error.message);
    }
  }); 
}

export {
  fetchTests,
  fetchAcademicTests,
  submitTest,
  submitAcademicTest,
  fetchSubmittedAnswers,
  generateReportAndProgram,
  fetchAcademicSubmittedAnswers
};
