import Video from 'components/common/Video';
import EvaluationLayout from './EvaluationLayout';
import DownloadButton from 'components/ui/buttons/DownloadButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RelaxationVideoStep = ({ data, onContinue, onPrevious }) => {
  const { t } = useTranslation();
  const {
    key,
    urls,
    downloadUrl,
    downloadBtnText,
    fileName,
    OnContinueDialog
  } = data;
  const customActions = downloadUrl ? (
    <DownloadButton
      url={downloadUrl}
      fileName={t(fileName)}
      text={downloadBtnText}
    />
  ) : null;
  const [isOnContinueDialogVisible, setIsOnContinueDialogVisible] =
    useState(false);
  const showDialog = () => {
    setIsOnContinueDialogVisible(true);
  };
  const hideDialog = () => {
    setIsOnContinueDialogVisible(false);
  };

  const relaxationType = JSON.parse(
    sessionStorage.getItem('programSummary')
  ).relaxationType.toLowerCase();
  const url = urls[relaxationType];

  return (
    <EvaluationLayout
      customActions={customActions}
      onContinue={OnContinueDialog ? showDialog : onContinue}
      onPrevious={onPrevious}
    >
      <div className='video-wrap'>
        <Video key={key} url={url} />
      </div>
      {isOnContinueDialogVisible && (
        <OnContinueDialog onConfirm={onContinue} onDismiss={hideDialog} />
      )}
    </EvaluationLayout>
  );
};

export default RelaxationVideoStep;
