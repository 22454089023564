import router from "components/router/Router";

const makeAPICall = async ({ method, url, params, body, headers }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const queryParams = new URLSearchParams(params);
      const requestHeaders = {
        "Content-Type": "application/json",
        ...headers,
      };
      const res = await fetch(`${url}?${queryParams.toString()}`, {
        method,
        body,
        headers: requestHeaders,
      });
      if (res.status === 401) {
        reject();
        router.navigate("/login");
      }

      const contentType = res.headers.get('content-type')
      const parsedResponse = (contentType?.includes('application/json')) ? await res.json() : resolve({})

      if(parsedResponse.status === undefined) {
        resolve(parsedResponse)
      } else {
        const {response, status} = parsedResponse;
        if (res.status !== 200 || status.isError) {
          throw new Error(status.message);
        }
        resolve(response);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const post = ({ url, params, body, headers }) =>
  makeAPICall({ url, params, body, headers, method: "POST" });
const get = ({ url, params, body, headers }) =>
  makeAPICall({ url, params, body, headers, method: "GET" });
const patch = ({ url, params, body, headers }) =>
  makeAPICall({ url, params, body, headers, method: "PATCH" });

export { post, get, patch };
