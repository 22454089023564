import "./EvaluationLayout.scss";
import Button from "components/ui/buttons/Button";
import { ReactComponent as LeftCaret } from "static/icons/left-caret.svg";
import { ReactComponent as RightCaret } from "static/icons/right-caret.svg";
import useDeviceDetector from "hooks/useDeviceDetector";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const EvaluationLayout = ({ primaryBtnText, isNextBtnDisabled, isBackBtnDisabled, isPrimaryBtnDisabled, hidePrimaryBtn, isBtnLoading, customActions, children, onContinue, onPrevious }) => {
  const { isLarge } = useDeviceDetector();
  const { t } = useTranslation();

  return (
    <div className="view">
      <div className="content">
        {isLarge && (
          <LeftCaret
            className={classNames("nav-arrows", {
              disabled: isBackBtnDisabled,
            })}
            onClick={isBackBtnDisabled ? undefined : onPrevious}
          />
        )}
        {children}
        {isLarge && (
          <RightCaret
            className={classNames("nav-arrows", {
              disabled: isNextBtnDisabled,
            })}
            onClick={isNextBtnDisabled ? undefined : onContinue}
          />
        )}
      </div>
      <div className="actions">
        {!isLarge && (
          <LeftCaret
            className={classNames("nav-arrows", {
              disabled: isBackBtnDisabled,
            })}
            onClick={isBackBtnDisabled ? undefined : onPrevious}
          />
        )}
        <div className="btns">
          {customActions}
          {hidePrimaryBtn ? null : (
            <Button
              className="primary-btn"
              disabled={isPrimaryBtnDisabled}
              onClick={onContinue}
              type="PRIMARY"
              isLoading={isBtnLoading}
            >
              {t(primaryBtnText || "general.continue")}
            </Button>
          )}
        </div>
        {!isLarge && (
          <RightCaret
            className={classNames("nav-arrows", {
              disabled: isNextBtnDisabled,
            })}
            onClick={isNextBtnDisabled ? undefined : onContinue}
          />
        )}
      </div>
    </div>
  );
};

export default EvaluationLayout;
